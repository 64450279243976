export const medias = [
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594104/imgg_2_aoexjg.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594104/IMG_7633_ofg3yx.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594104/IMG_7632_btg6ry.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594103/IMG_7630_wvr7xw.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594102/IMG_7629_rxnymo.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594096/IMG_7628_xcaf6a.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594095/IMG_7626_x8xsl1.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594045/IMG_7624_e51rv8.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594120/imm_2_mpujdp.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594044/IMG_7616_gwlepf.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594044/IMG_7619_wytpbn.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594044/IMG_7617_zrbqdm.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594044/IMG_7615_wabgox.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594044/IMG_7614_yd3l69.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594044/IMG_7618_v7kzbb.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594045/IMG_7621_dshijf.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594045/IMG_7622_wgeey6.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594045/IMG_7620_gygkxz.jpg",
  },
];

export const ojude = [
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1720133300/oju10_q2noar.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1720133296/oju9_iybfpe.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1720133295/oju7_a5nyxx.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1720133293/oju1_swm9wy.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1720133294/oju8_u9lcjt.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1720133293/oju5_gslsji.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1720133292/oju6_kfttc9.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1720133291/oju3_opkxrv.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1719594120/imm_2_mpujdp.jpg",
  },
  {
    url: "https://res.cloudinary.com/dtkbcuiit/image/upload/v1720133290/oju2_nuwigo.jpg",
  },
];
