<template>
  <section id="awards" class="my-[70px] md:my-[140px]">
    <div class="max-w-[100%] mx-auto w-[80%]">
      <h3 class="text-3xl md:text-7xl opacity-20 font-bold">Accomplishments</h3>

      <div class="mt-10 grid grid-cols-1 md:grid-cols-4 gap-10">
        <div class="bg-[#1f2f3b] shadow-xl shadow-[#1f2f3b] p-5 rounded-xl">
          <div class="space-y-5">
            <h4 class="border rounded-lg bg-accent/20 inline-block px-3 py-1">
              2018
            </h4>
            <p class="font-bold">
              Nominee for The Future Awards Africa Prize for Arts and Culture
            </p>
            <!-- <p>
              he was nominated for The Future Awards Africa Prize for Arts and
              Culture.
            </p> -->
          </div>
        </div>
        <div class="bg-[#1f2f3b] shadow-xl shadow-[#1f2f3b] p-5 rounded-xl">
          <div class="space-y-5">
            <h4 class="border rounded-lg bg-accent/20 inline-block px-3 py-1">
              2020
            </h4>
            <p class="font-bold">Royal Africa Medal for Creative Culture</p>
            <!-- <p>
              he was awarded the Royal Africa Medal for Creative Culture by
              H.I.M. Ooni Adeyeye Enitan Ogunwusi OJAJAII, The Ooni of Ife. He
              was also inducted Ito the royal court of His Majesty.
            </p> -->
          </div>
        </div>
        <div class="bg-[#1f2f3b] shadow-xl shadow-[#1f2f3b] p-5 rounded-xl">
          <div class="space-y-5">
            <h4 class="border rounded-lg bg-accent/20 inline-block px-3 py-1">
              2023
            </h4>
            <p class="font-bold">Doctor of Fine Arts</p>
            <!-- <p>
              he was conferred upon an honorary doctorate degree “Doctor of Fine
              Arts” by the Myles Leadership University for his unmatched skill
              in his creations and many contributions to the advancement of art
              in his community.
            </p> -->
          </div>
        </div>
        <div class="bg-[#1f2f3b] shadow-xl shadow-[#1f2f3b] p-5 rounded-xl">
          <div class="space-y-5">
            <h4 class="border rounded-lg bg-accent/20 inline-block px-3 py-1">
              2024
            </h4>
            <p class="font-bold">Art exhibition and presentation of prizes</p>
            <!-- <p>
              He partnered with the Lagos State Health Management Agency to
              train 15 orphans from selected Orphanages across Lagos state in
              the art of painting, which culminated in an art exhibition and
              presentation of prizes to the participants by the Executive
              Governor of Lagos State, Babajide SanwoOlu.
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts"></script>

<style scoped></style>
