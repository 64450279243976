<template>
  <section class="">
    <!--  -->
    <div
      class="mt-10 w-full mx-auto md:my-20 px-5 md:px-20 py-10 md:py-20 rounded-3xl bg-gradient-to-r from-[#111927] via-[#242c56b7] to-[#111927]"
    >
      <p class="capitalize text-xl font-bold tracking-wider text-center">
        As a Humanitarian
      </p>
      <h2
        class="text-3xl md:text-4xl text-center font-medium animate__animated animate__fadeInUp animate__delay-1s pt-5"
      >
        Fola David Care Foundation
      </h2>

      <div
        class="flex flex-col lg:flex-row items-center gap-5 md:gap-10 mt-10 md:mt-20"
      >
        <a
          href="http://www.foladavidfoundation.org"
          target="_blank"
          rel="noopener noreferrer"
          class="h-full lg:h-[600px] w-full rounded-lg"
        >
          <div class="animate__animated animate__zoomIn animate__delay-1s">
            <img
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718884478/foundation_jacd9b.jpg"
              class="h-full lg:h-[600px] w-full rounded-lg object-cover hover:bg-black hover:opacity-80 object-top"
              alt="..."
            />
          </div>
        </a>

        <div class="w-full animate__animated animate__zoomIn animate__delay-1s">
          <!-- <h3 class="text-xl md:text-2xl pb-5">Passion To Make A Difference</h3> -->
          <p class="text-base leading-loose pt-3">
            Dr. FolaDavid has improved the quality of life by cutting across
            several countries and regions to overcome challenges facing
            families, communities, and regions by providing them with what they
            need for healthy living and empower them through creative projects,
            skill acquisition and art entrepreneurship development.
          </p>
          <p class="text-base leading-loose pt-3">
            At the Foladavid Care Foundation, the work cuts across a wide range
            of causes in healthcare and art, collaborating with individuals,
            governments and organizations to pursue healthy living,
            sustainability through the arts, and cooperation across a wide range
            of projects in many communities, nations and regions.
          </p>
          <p class="text-base leading-loose pt-3">
            We ensure that children and mothers are able to have access to basic
            human amenities such as food, drugs, clothing and shelter, with the
            aim of improving their quality of life. To also help improve
            creativity amongst children, we create well-structured programs
            where children can actively participate in events that are sure to
            find and nurture innate talents. The goal of these programs is to
            help give these kids better futures.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts"></script>

<style scoped></style>
