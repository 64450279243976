export const getDps = [
  { tribe: "Fulani", link: "https://getdp.co/Xyi" },
  { tribe: "Hausa", link: "https://getdp.co/XyV" },
  { tribe: "Yoruba", link: "https://getdp.co/Xyq" },
  { tribe: "Igbo", link: "https://getdp.co/Xyo" },
  { tribe: "Ibibio", link: "https://getdp.co/Xyj" },
  { tribe: "Ijaw", link: "https://getdp.co/XyX" },
  { tribe: "kanuri", link: "https://getdp.co/Xyp" },
  { tribe: "Tiv", link: "https://getdp.co/XyL" },
  { tribe: "Nupe", link: "https://getdp.co/XCR" },
  { tribe: "Efik", link: "https://getdp.co/XD0" },
  { tribe: "Annang", link: "https://getdp.co/XDa" },
  { tribe: "Igala", link: "https://getdp.co/XDu" },
  { tribe: "Gwari", link: "https://getdp.co/XD1" },
  { tribe: "Jakun", link: "https://getdp.co/XDS" },
  { tribe: "Edo", link: "https://getdp.co/XDM" },
  { tribe: "Abua", link: "https://getdp.co/pkK" },
  { tribe: "Abayon", link: "https://getdp.co/pkd" },
  { tribe: "Achipa", link: "https://getdp.co/pkP" },
  { tribe: "Adim", link: "https://getdp.co/pki" },
  { tribe: "Adun", link: "https://getdp.co/pkV" },
  { tribe: "Affade", link: "https://getdp.co/pkj" },
  { tribe: "Afizere", link: "https://getdp.co/pko" },
  { tribe: "Afo", link: "https://getdp.co/pkX" },
  { tribe: "Agbo", link: "https://getdp.co/pkp" },
  { tribe: "Akaju-Ndem", link: "https://getdp.co/pkL" },
  { tribe: "Akweya-yachi", link: "https://getdp.co/pkN" },
  { tribe: "Alago", link: "https://getdp.co/pkr" },
  { tribe: "Amo", link: "https://getdp.co/pks" },
  { tribe: "Anaguta", link: "https://getdp.co/pkt" },
  { tribe: "Andoni", link: "https://getdp.co/pkv" },
  { tribe: "Angas", link: "https://getdp.co/pkw" },
  { tribe: "Ankwei", link: "https://getdp.co/pkE" },
  { tribe: "Anyima", link: "https://getdp.co/pkZ" },
  { tribe: "Attakar", link: "https://getdp.co/pkF" },
  { tribe: "Auyoka", link: "https://getdp.co/pky" },
  { tribe: "Awori", link: "https://getdp.co/pkz" },
  { tribe: "Ayu", link: "https://getdp.co/pkB" },
  { tribe: "Bachama", link: "https://getdp.co/p49" },
  { tribe: "Bachere", link: "https://getdp.co/p4b" },
  { tribe: "Bada", link: "https://getdp.co/p4T" },
  { tribe: "Bade", link: "https://getdp.co/p4c" },
  { tribe: "Bahumono", link: "https://getdp.co/p4U" },
  { tribe: "Bakulung", link: "https://getdp.co/p4f" },
  { tribe: "Bali", link: "https://getdp.co/p4W" },
  { tribe: "Bambora", link: "https://getdp.co/p4g" },
  { tribe: "Babur", link: "https://getdp.co/p4K" },
  { tribe: "baruba", link: "https://getdp.co/p4h" },
  { tribe: "Bamkubo", link: "https://getdp.co/p4P" },
  { tribe: "Banda", link: "https://getdp.co/p4i" },
  { tribe: "Banka", link: "https://getdp.co/p4V" },
  { tribe: "Banso", link: "https://getdp.co/p4j" },
  { tribe: "Bara", link: "https://getdp.co/p4o" },
  { tribe: "Barke", link: "https://getdp.co/p4X" },
  { tribe: "Bashiri", link: "https://getdp.co/p88" },
  { tribe: "Bassa", link: "https://getdp.co/p84" },
  { tribe: "Batta", link: "https://getdp.co/p8k" },
  { tribe: "Baushi", link: "https://getdp.co/p83" },
  { tribe: "Baya", link: "https://getdp.co/p8d" },
  { tribe: "Bekwarra", link: "https://getdp.co/p82" },
  { tribe: "Bele", link: "https://getdp.co/p8M" },
  { tribe: "Betso", link: "https://getdp.co/p8S" },
  { tribe: "Bette", link: "https://getdp.co/p81" },
  { tribe: "Bilei", link: "https://getdp.co/p8u" },
  { tribe: "Bille", link: "https://getdp.co/p8a" },
  { tribe: "Bina", link: "https://getdp.co/p80" },
  { tribe: "Bini (Edo)", link: "https://getdp.co/p4R" },
  { tribe: "Birom", link: "https://getdp.co/p4n" },
  { tribe: "Bkkos", link: "https://getdp.co/p4Q" },
  { tribe: "Bobua", link: "https://getdp.co/p4J" },
  { tribe: "Boki(Nki)", link: "https://getdp.co/p4I" },
  { tribe: "Boko", link: "https://getdp.co/p4H" },
  { tribe: "Bole(Bolewa)", link: "https://getdp.co/p4G" },
  { tribe: "Boma", link: "https://getdp.co/p4m" },
  { tribe: "Bomboro", link: "https://getdp.co/p4x" },
  { tribe: "Botlere", link: "https://getdp.co/p4D" },
  { tribe: "Buduma", link: "https://getdp.co/p8_" },
  { tribe: "Buji", link: "https://getdp.co/p4C" },
  { tribe: "Buli", link: "https://getdp.co/p4B" },
  { tribe: "Bunu", link: "https://getdp.co/p4z" },
  { tribe: "Bura", link: "https://getdp.co/p4y" },
  { tribe: "Burak", link: "https://getdp.co/p4F" },
  { tribe: "Burma", link: "https://getdp.co/p4Z" },
  { tribe: "Buru", link: "https://getdp.co/p4E" },
  { tribe: "Bwall", link: "https://getdp.co/p4A" },
  { tribe: "Bwatiye", link: "https://getdp.co/p45" },
  { tribe: "Bwazza", link: "https://getdp.co/p4s" },
  { tribe: "chama", link: "https://getdp.co/p_M" },
  { tribe: "chamba", link: "https://getdp.co/p_3" },
  { tribe: "chamo", link: "https://getdp.co/p_2" },
  { tribe: "chibok", link: "https://getdp.co/p_d" },
  { tribe: "chinine", link: "https://getdp.co/p_k" },
  { tribe: "chip", link: "https://getdp.co/p_4" },
  { tribe: "chokobo", link: "https://getdp.co/p_8" },
  { tribe: "chukkol", link: "https://getdp.co/p__" },
  { tribe: "daba", link: "https://getdp.co/p_l" },
  { tribe: "dadiya", link: "https://getdp.co/p_9" },
  { tribe: "daka", link: "https://getdp.co/p_b" },
  { tribe: "dakarkari", link: "https://getdp.co/p_T" },
  { tribe: "danda", link: "https://getdp.co/p_U" },
  { tribe: "dangsa", link: "https://getdp.co/p_e" },
  { tribe: "daza", link: "https://getdp.co/p_f" },
  { tribe: "degema", link: "https://getdp.co/p_W" },
  { tribe: "deno", link: "https://getdp.co/p_g" },
  { tribe: "Dghwede", link: "https://getdp.co/p_h" },
  { tribe: "diba", link: "https://getdp.co/p_K" },
  { tribe: "Deomak", link: "https://getdp.co/p_i" },
  { tribe: "duma", link: "https://getdp.co/p_P" },
  { tribe: "ebana", link: "https://getdp.co/p_o" },
  { tribe: "Ebirra", link: "https://getdp.co/p_X" },
  { tribe: "ebu", link: "https://getdp.co/p_p" },
  { tribe: "egede", link: "https://getdp.co/p_L" },
  { tribe: "eggon", link: "https://getdp.co/p_q" },
  { tribe: "egun", link: "https://getdp.co/p_N" },
  { tribe: "eket", link: "https://getdp.co/p_r" },
  { tribe: "esan", link: "https://getdp.co/p_s" },
  { tribe: "etche", link: "https://getdp.co/p_O" },
  { tribe: "etolu", link: "https://getdp.co/p_v" },
  { tribe: "etung", link: "https://getdp.co/p_t" },
  { tribe: "etuno", link: "https://getdp.co/p_5" },
  { tribe: "fyam", link: "https://getdp.co/p_A" },
  { tribe: "Ga'anda", link: "https://getdp.co/p_F" },
  { tribe: "gade", link: "https://getdp.co/p_y" },
  { tribe: "galambi", link: "https://getdp.co/p_B" },
  { tribe: "gamergu", link: "https://getdp.co/p_6" },
  { tribe: "gavako", link: "https://getdp.co/p_C" },
  { tribe: "gbedde", link: "https://getdp.co/p_D" },
  { tribe: "gengle", link: "https://getdp.co/p_x" },
  { tribe: "gera", link: "https://getdp.co/p_m" },
  { tribe: "geruma", link: "https://getdp.co/p_G" },
  { tribe: "ginkwak", link: "https://getdp.co/p_H" },
  { tribe: "gira", link: "https://getdp.co/p_I" },
  { tribe: "gizigz", link: "https://getdp.co/p_7" },
  { tribe: "goernai", link: "https://getdp.co/p_J" },
  { tribe: "gokana", link: "https://getdp.co/p_n" },
  { tribe: "gombi", link: "https://getdp.co/p_R" },
  { tribe: "gonia", link: "https://getdp.co/pl1" },
  { tribe: "gornun", link: "https://getdp.co/plu" },
  { tribe: "gubi", link: "https://getdp.co/plS" },
  { tribe: "gude", link: "https://getdp.co/plM" },
  { tribe: "gudu", link: "https://getdp.co/pl2" },
  { tribe: "gure", link: "https://getdp.co/pld" },
  { tribe: "gurmana", link: "https://getdp.co/pl3" },
  { tribe: "gururntum", link: "https://getdp.co/plk" },
  { tribe: "gusu", link: "https://getdp.co/pl4" },
  { tribe: "gwa(gurawa)", link: "https://getdp.co/pl8" },
  { tribe: "gwamba", link: "https://getdp.co/pl8" },
  { tribe: "gwandara", link: "https://getdp.co/pll" },
  { tribe: "gwom", link: "https://getdp.co/pl9" },
  { tribe: "gwoza", link: "https://getdp.co/plb" },
  { tribe: "gyem", link: "https://getdp.co/plb" },
  { tribe: "higi", link: "https://getdp.co/p9h" },
  { tribe: "holma", link: "https://getdp.co/p9P" },
  { tribe: "hona", link: "https://getdp.co/p9i" },
  { tribe: "ibeno", link: "https://getdp.co/p9V" },
  { tribe: "ichen", link: "https://getdp.co/p9j" },
  { tribe: "idoma", link: "https://getdp.co/p9o" },
  { tribe: "ijumu", link: "https://getdp.co/p9X" },
  { tribe: "ikorn", link: "https://getdp.co/p9p" },
  { tribe: "ilyala", link: "https://getdp.co/p9L" },
  { tribe: "ilzonjo", link: "https://getdp.co/p9q" },
  { tribe: "irigwe", link: "https://getdp.co/p9N" },
  { tribe: "isoko", link: "https://getdp.co/p9r" },
  { tribe: "itsekiri", link: "https://getdp.co/p9s" },
  { tribe: "jaba", link: "https://getdp.co/p9O" },
  { tribe: "jahuna", link: "https://getdp.co/p9t" },
  { tribe: "jaku", link: "https://getdp.co/p95" },
  { tribe: "jara", link: "https://getdp.co/p9A" },
  { tribe: "jere", link: "https://getdp.co/p9v" },
  { tribe: "jero", link: "https://getdp.co/p9w" },
  { tribe: "jibu", link: "https://getdp.co/p9E" },
  { tribe: "jidda-abu", link: "https://getdp.co/p9F" },
  { tribe: "jimbin", link: "https://getdp.co/p9y" },
  { tribe: "jirai", link: "https://getdp.co/p9z" },
  { tribe: "jonjo", link: "https://getdp.co/p9B" },
  { tribe: "jukun", link: "https://getdp.co/p96" },
  { tribe: "kaba", link: "https://getdp.co/pbl" },
  { tribe: "kadara", link: "https://getdp.co/p9D" },
  { tribe: "kafanchan", link: "https://getdp.co/p9x" },
  { tribe: "kaje", link: "https://getdp.co/pby" },
  { tribe: "kajuru", link: "https://getdp.co/pb9" },
  { tribe: "kaka", link: "https://getdp.co/p9G" },
  { tribe: "kamaku", link: "https://getdp.co/pbb" },
  { tribe: "kambari", link: "https://getdp.co/pbT" },
  { tribe: "kambu", link: "https://getdp.co/pbc" },
  { tribe: "kamo", link: "https://getdp.co/pbU" },
  { tribe: "kanakuru", link: "https://getdp.co/pbe" },
  { tribe: "kanembu", link: "https://getdp.co/pbW" },
  { tribe: "kanikon", link: "https://getdp.co/pbf" },
  { tribe: "kantana", link: "https://getdp.co/pbg" },
  { tribe: "karekare", link: "https://getdp.co/pTP" },
  { tribe: "karimjo", link: "https://getdp.co/pTg" },
  { tribe: "kariya", link: "https://getdp.co/pTc" },
  { tribe: "katab", link: "https://getdp.co/pTU" },
  { tribe: "kanern", link: "https://getdp.co/pTK" },
  { tribe: "kenton", link: "https://getdp.co/pTh" },
  { tribe: "kiballo", link: "https://getdp.co/pTi" },
  { tribe: "kilba", link: "https://getdp.co/pTV" },
  { tribe: "kirfi", link: "https://getdp.co/pTj" },
  { tribe: "koma", link: "https://getdp.co/pTo" },
  { tribe: "kona", link: "https://getdp.co/pTX" },
  { tribe: "koro", link: "https://getdp.co/pTp" },
  { tribe: "kubi", link: "https://getdp.co/pTL" },
  { tribe: "kudachano", link: "https://getdp.co/pTN" },
  { tribe: "kugama", link: "https://getdp.co/pTr" },
  { tribe: "kulere", link: "https://getdp.co/pTs" },
  { tribe: "kunini", link: "https://getdp.co/pTO" },
  { tribe: "kurama", link: "https://getdp.co/pTt" },
  { tribe: "kurdul", link: "https://getdp.co/pT5" },
  { tribe: "kushi", link: "https://getdp.co/pTA" },
  { tribe: "kuteb", link: "https://getdp.co/pTv" },
  { tribe: "kutin", link: "https://getdp.co/pTw" },
  { tribe: "kwalla", link: "https://getdp.co/pTZ" },
  { tribe: "kwami", link: "https://getdp.co/pTF" },
  { tribe: "kwanchi", link: "https://getdp.co/pTy" },
  { tribe: "kwanka", link: "https://getdp.co/pTz" },
  { tribe: "kwaro", link: "https://getdp.co/pTW" },
  { tribe: "kwato", link: "https://getdp.co/pTf" },
  { tribe: "kyenga", link: "https://getdp.co/pTe" },
  { tribe: "laaru", link: "https://getdp.co/pcS" },
  { tribe: "lakka", link: "https://getdp.co/pcM" },
  { tribe: "lala", link: "https://getdp.co/pc2" },
  { tribe: "lama", link: "https://getdp.co/pcd" },
  { tribe: "lamja", link: "https://getdp.co/pc3" },
  { tribe: "lau", link: "https://getdp.co/pck" },
  { tribe: "limono", link: "https://getdp.co/pc4" },
  { tribe: "longuda", link: "https://getdp.co/pc8" },
  { tribe: "lopa", link: "https://getdp.co/pc_" },
  { tribe: "mabo", link: "https://getdp.co/pcb" },
  { tribe: "mada", link: "https://getdp.co/pcT" },
  { tribe: "mama", link: "https://getdp.co/pcU" },
  { tribe: "mambilla", link: "https://getdp.co/pcY" },
  { tribe: "manchok", link: "https://getdp.co/pcf" },
  { tribe: "mandara", link: "https://getdp.co/pcW" },
  { tribe: "margi", link: "https://getdp.co/pcg" },
  { tribe: "matakarn", link: "https://getdp.co/pcK" },
  { tribe: "mbembe", link: "https://getdp.co/pch" },
  { tribe: "mbol", link: "https://getdp.co/pcP" },
  { tribe: "mbube", link: "https://getdp.co/pci" },
  { tribe: "mbula", link: "https://getdp.co/pcV" },
  { tribe: "mbum", link: "https://getdp.co/pcj" },
  { tribe: "memyang", link: "https://getdp.co/pco" },
  { tribe: "miango", link: "https://getdp.co/pcX" },
  { tribe: "miligili", link: "https://getdp.co/pcp" },
  { tribe: "miya", link: "https://getdp.co/pcL" },
  { tribe: "mobber", link: "https://getdp.co/pcq" },
  { tribe: "montol", link: "https://getdp.co/pcN" },
  { tribe: "moruwa", link: "https://getdp.co/pcr" },
  { tribe: "muchaila", link: "https://getdp.co/pcs" },
  { tribe: "mumuye", link: "https://getdp.co/pcO" },
  { tribe: "mundang", link: "https://getdp.co/pct" },
  { tribe: "munga", link: "https://getdp.co/pc5" },
  { tribe: "mushere", link: "https://getdp.co/pcA" },
  { tribe: "mwahavul", link: "https://getdp.co/pcv" },
  { tribe: "ndoro", link: "https://getdp.co/pcw" },
  { tribe: "ngamo", link: "https://getdp.co/pcZ" },
  { tribe: "ngizim", link: "https://getdp.co/pcZ" },
  { tribe: "ngweshe", link: "https://getdp.co/pcF" },
  { tribe: "ningi", link: "https://getdp.co/pcy" },
  { tribe: "ninzam", link: "https://getdp.co/pcz" },
  { tribe: "njayi", link: "https://getdp.co/pcB" },
  { tribe: "nkim", link: "https://getdp.co/pc6" },
  { tribe: "nkum", link: "https://getdp.co/pcC" },
  { tribe: "nokere", link: "https://getdp.co/pcx" },
  { tribe: "nunku", link: "https://getdp.co/pcm" },
  { tribe: "nupe", link: "https://getdp.co/pcG" },
  { tribe: "nyandang", link: "https://getdp.co/pcH" },
  { tribe: "ododop", link: "https://getdp.co/pUS" },
  { tribe: "ogori", link: "https://getdp.co/pU1" },
  { tribe: "okobo", link: "https://getdp.co/pUu" },
  { tribe: "okpamheri", link: "https://getdp.co/pUa" },
  { tribe: "oron", link: "https://getdp.co/pcR" },
  { tribe: "ouguri", link: "https://getdp.co/pcn" },
  { tribe: "owan", link: "https://getdp.co/pcQ" },
  { tribe: "owe", link: "https://getdp.co/pcJ" },
  { tribe: "oworo", link: "https://getdp.co/pc7" },
  { tribe: "pa'a", link: "https://getdp.co/pUT" },
  { tribe: "pai", link: "https://getdp.co/pUb" },
  { tribe: "palli", link: "https://getdp.co/pU9" },
  { tribe: "panyam", link: "https://getdp.co/pUl" },
  { tribe: "pero", link: "https://getdp.co/pU_" },
  { tribe: "pire", link: "https://getdp.co/pU8" },
  { tribe: "polchi habe", link: "https://getdp.co/pU4" },
  { tribe: "pongo", link: "https://getdp.co/pUk" },
  { tribe: "potopo", link: "https://getdp.co/pU3" },
  { tribe: "pyapun", link: "https://getdp.co/pUd" },
  { tribe: "qua", link: "https://getdp.co/pUd" },
  { tribe: "Qanawuri", link: "https://getdp.co/pUM" },
  { tribe: "Rebina", link: "https://getdp.co/p9H" },
  { tribe: "Reshe", link: "https://getdp.co/p97" },
  { tribe: "Rindire", link: "https://getdp.co/p9J" },
  { tribe: "Rishuwa", link: "https://getdp.co/p9Q" },
  { tribe: "Ron", link: "https://getdp.co/p9n" },
  { tribe: "Rubu", link: "https://getdp.co/p9R" },
  { tribe: "Rukuba", link: "https://getdp.co/pb0" },
  { tribe: "Rumada", link: "https://getdp.co/pba" },
  { tribe: "Rumada", link: "https://getdp.co/pbu" },
  { tribe: "Sakbe", link: "https://getdp.co/pb1" },
  { tribe: "Sanga", link: "https://getdp.co/pbS" },
  { tribe: "Sate", link: "https://getdp.co/pbM" },
  { tribe: "Saya", link: "https://getdp.co/pb2" },
  { tribe: "Shan-Shan", link: "https://getdp.co/pbd" },
  { tribe: "Shanga", link: "https://getdp.co/pb3" },
  { tribe: "Shangawa", link: "https://getdp.co/pbo" },
  { tribe: "shira", link: "https://getdp.co/pbX" },
  { tribe: "shomo", link: "https://getdp.co/pbp" },
  { tribe: "shuwa", link: "https://getdp.co/pbL" },
  { tribe: "sikdi", link: "https://getdp.co/pbq" },
  { tribe: "siri", link: "https://getdp.co/pbN" },
  { tribe: "srubu", link: "https://getdp.co/pbO" },
  { tribe: "sukur", link: "https://getdp.co/pb5" },
  { tribe: "sura", link: "https://getdp.co/pbA" },
  { tribe: "tangale", link: "https://getdp.co/pbv" },
  { tribe: "teme", link: "https://getdp.co/pbw" },
  { tribe: "tera", link: "https://getdp.co/pbE" },
  { tribe: "teshena", link: "https://getdp.co/pbZ" },
  { tribe: "tigon", link: "https://getdp.co/pbB" },
  { tribe: "tikar", link: "https://getdp.co/pb6" },
  { tribe: "tula", link: "https://getdp.co/pbD" },
  { tribe: "tur", link: "https://getdp.co/pbx" },
  { tribe: "ubbo", link: "https://getdp.co/pbH" },
  { tribe: "ufia", link: "https://getdp.co/pbI" },
  { tribe: "ukelle", link: "https://getdp.co/pb7" },
  { tribe: "ukwani", link: "https://getdp.co/pbJ" },
  { tribe: "uncinda", link: "https://getdp.co/pbQ" },
  { tribe: "uneme", link: "https://getdp.co/pbn" },
  { tribe: "ura", link: "https://getdp.co/pbR" },
  { tribe: "urhobo", link: "https://getdp.co/pT0" },
  { tribe: "utonkong", link: "https://getdp.co/pTa" },
  { tribe: "uyanga", link: "https://getdp.co/pTu" },
  { tribe: "vemgo", link: "https://getdp.co/pT1" },
  { tribe: "verre", link: "https://getdp.co/pTS" },
  { tribe: "wagga", link: "https://getdp.co/pT3" },
  { tribe: "waja", link: "https://getdp.co/pTk" },
  { tribe: "waka", link: "https://getdp.co/pT4" },
  { tribe: "warja", link: "https://getdp.co/pT8" },
  { tribe: "warji", link: "https://getdp.co/pT_" },
  { tribe: "wula", link: "https://getdp.co/pTl" },
  { tribe: "wurbo", link: "https://getdp.co/pT9" },
  { tribe: "wurkun", link: "https://getdp.co/pTT" },
  { tribe: "yache", link: "https://getdp.co/pTD" },
  { tribe: "yagba", link: "https://getdp.co/pTx" },
  { tribe: "yakurr", link: "https://getdp.co/pTm" },
  { tribe: "yalla", link: "https://getdp.co/pTG" },
  { tribe: "yandang", link: "https://getdp.co/pc1" },
  { tribe: "yergan", link: "https://getdp.co/pcu" },
  { tribe: "yott", link: "https://getdp.co/pca" },
  { tribe: "yumu", link: "https://getdp.co/pc0" },
  { tribe: "yungur", link: "https://getdp.co/pTR" },
  { tribe: "yuom", link: "https://getdp.co/pTn" },
  { tribe: "zabara", link: "https://getdp.co/pTQ" },
  { tribe: "zaranda", link: "https://getdp.co/pTJ" },
  { tribe: "zarma", link: "https://getdp.co/pT7" },
  { tribe: "zayam", link: "https://getdp.co/pTI" },
  { tribe: "zul", link: "https://getdp.co/pTH" },
];
