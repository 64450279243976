<template>
  <div>
    <section id="portfolio" class="active">
      <div>
        <div class="marquee--reverse m-auto md:mt-8 flex gap-8 overflow-hidden">
          <div
            class="marquee__group flex min-w-full flex-shrink-0 items-center justify-around gap-8 delay-[31s]"
          >
            <img
              class="h-36 md:h-72 w-[200px] md:w-[400px] object-cover"
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718805192/pic16_tzqqlw.jpg"
              alt=""
            />
            <img
              class="h-36 md:h-72 w-[200px] md:w-[400px] object-cover"
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718805185/pic14_klypcl.jpg"
              alt=""
            />
            <img
              class="h-36 md:h-72 w-[200px] md:w-[400px] object-cover"
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718824727/beni_o0vuiq.png"
              alt=""
            />
            <img
              class="h-36 md:h-72 w-[200px] md:w-[400px] object-cover"
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718805183/pic13_lfwngn.jpg"
              alt=""
            /><img
              class="h-36 md:h-72 w-[200px] md:w-[400px] object-cover"
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718805177/pic11_bbqmn9.jpg"
              alt=""
            />
          </div>
          <div
            class="marquee__group flex min-w-full flex-shrink-0 items-center justify-around gap-8 delay-[31s]"
          >
            <img
              class="h-36 md:h-72 w-[200px] md:w-[400px] object-cover"
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718805173/pic10_rvzb5n.jpg"
              alt=""
            />
            <img
              class="h-36 md:h-72 w-[200px] md:w-[400px] object-cover"
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718805170/pic7_mrzcmr.webp"
              alt=""
            />
            <img
              class="h-36 md:h-72 w-[200px] md:w-[400px] object-contain bg-[#333]"
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718824727/oduduwa_ciktjl.png"
            />
            <img
              class="h-36 md:h-72 w-[200px] md:w-[400px] object-cover"
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718805156/pic5_uteq7e.jpg"
              alt=""
            /><img
              class="h-36 md:h-72 w-[200px] md:w-[400px] object-cover"
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718805159/pic2_jkmong.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
