<template>
  <section class="py-10 md:py-32 bg-white text-[#333]">
    <div class="container2">
      <div class="flex items-end justify-between">
        <div class="">
          <span
            class="py-1 px-3 rounded-md text-white text-xs lg:text-sm font-medium uppercase tracking-wider border border-default-300 bg-default-300"
            >sponsor</span
          >
          <a target="_blank" href="https://en.wikipedia.org/wiki/Tolaram_Group">
            <div
              class="font-medium capitalize h-[50px] md:h-[100px] text-white mt-4"
            >
              <img
                src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718808717/sponsor_qgwqqp.png"
                class="h-full"
                alt=""
              /></div
          ></a>
          <div>
            <span class="text-sm md:text-lg">
              Tolaram Group is a holding company headquartered in Singapore with
              diversified business interests in consumer goods, fintech,
              infrastructure and industrials. It has a presence across Asia,
              Africa, Europe and South America. Tolaram Group successfully
              creates new product categories independently or in partnership
              with other multinational companies and quickly becomes the market
              leader in the emerging markets where it operates.
            </span>

            <blockquote
              class="text-lg md:text-xl italic font-semibold text-default-800 px-5 w-full md:w-[60%] mx-auto mt-10"
            >
              <svg
                class="w-8 h-8 text-gray-700 dark:text-gray-600 mb-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 14"
              >
                <path
                  d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"
                />
              </svg>
              <p class="text-gray-700 text-sm md:text-base">
                " We are proud to collaborate with esteemed organizations and
                institutions that share our vision of celebrating and promoting
                Nigeria's cultural diversity."
              </p>
            </blockquote>
          </div>

          <div class="flex justify-center mt-5 md:mt-20">
            <a
              target="_blank"
              href="https://en.wikipedia.org/wiki/Tolaram_Group"
              class="inline-flex items-center hover:text-primary-400 justify-center gap-2 text-xs md:text-base py-3 px-8 rounded-md text-white bg-primary/90 hover:bg-primary transition-all duration-500"
              >Read More
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-move-right-icon h-6 w-6"
              >
                <path d="M18 8L22 12L18 16"></path>
                <path d="M2 12H22"></path></svg
            ></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
