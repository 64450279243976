<template>
  <section class="lg:py-20 py-10">
    <div class="container">
      <div class="flex items-end justify-between mb-10">
        <div
          class="max-w-2xl mx-auto text-center animate__animated animate__fadeInUp animate__delay-1s"
        >
          <span
            class="text-4xl lg:text-5xl font-medium capitalize text-default-700 my-4"
            >Get Involved</span
          >
          <p class="text-lg text-default-700 font-medium mt-5">
            Be part of our movement.
          </p>
        </div>
      </div>
      <div class="grid lg:grid-cols-2 grid-cols-1 gap-6 items-center">
        <div
          class="relative overflow-hidden animate__animated animate__zoomIn animate__delay-2s"
        >
          <img
            src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718809947/hands_moz0nr.jpg"
            class="rounded-md h-full md:h-[600px] object-cover w-full"
            alt=""
          />
          <div class="absolute inset-0 rounded-md bg-black/40"></div>
        </div>
        <div
          class="lg:-ms-20 z-10 animate__animated animate__zoomIn animate__delay-2s"
        >
          <div
            class="divide-y divide-default-800 bg-default-500 rounded-md shadow"
          >
            <div class="p-6 flex flex-wrap sm:flex-nowrap items-center gap-6">
              <div>
                <div
                  class="h-12 w-12 rounded-md flex items-center justify-center text-xl border border-default-800 text-default-950 bg-white/5"
                >
                  <span>0</span>1
                </div>
              </div>
              <div>
                <h3 class="text-2xl font-medium text-default-950">
                  Volunteer Opportunities
                </h3>
                <p class="mt-3">
                  <span class="text-sm md:text-base"
                    >Join our team and contribute to the Unity in Diversity
                    Project. We welcome volunteers for various roles, from event
                    coordination to social media promotion.</span
                  >
                </p>
              </div>
            </div>
            <div class="p-6 flex flex-wrap sm:flex-nowrap items-center gap-6">
              <div>
                <div
                  class="h-12 w-12 rounded-md flex items-center justify-center text-xl border border-default-800 text-default-950 bg-white/5"
                >
                  <span>0</span>2
                </div>
              </div>
              <div>
                <h3 class="text-2xl font-medium text-default-950">
                  Participate Online
                </h3>
                <p class="text-base mt-3">
                  <span class="text-sm md:text-base">Use the hashtag </span>
                  <span class="font-bold text-warning">#RepYourTribe</span>
                  <span class="text-sm md:text-base"
                    >on social media to share your cultural heritage and join
                    the conversation. Follow us on [social media links] for
                    updates and more.</span
                  >
                </p>
              </div>
            </div>
            <div class="p-6 flex flex-wrap sm:flex-nowrap items-center gap-6">
              <div>
                <div
                  class="h-12 w-12 rounded-md flex items-center justify-center text-xl border border-default-800 text-default-950 bg-white/5"
                >
                  <span>0</span>3
                </div>
              </div>
              <div>
                <h3 class="text-2xl font-medium text-default-950">
                  Attend Events
                </h3>
                <p class="mt-3">
                  <span class="text-sm md:text-base"
                    >Check out our upcoming events and be part of the
                    celebration. From the Road to Record documentary screenings
                    to the World Record Attempt, there's something for
                    everyone.</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts"></script>

<style scoped></style>
