<template>
  <section id="about" class="my-[140px]">
    <div
      class="sm:w-[80%] px-5 flex flex-col lg:flex-row justify-between gap-10 md:gap-20 items-center md:max-w-[100%] mx-auto"
    >
      <div
        class="h-full w-full animate__animated animate__zoomIn animate__faster md:h-[50%] md:w-[60%] border-8 border-[#1e2a3ff9] rounded-2xl"
      >
        <img
          src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1720020124/about_eo6hqw.jpg"
          alt="about me"
          class="h-full w-full p-5 rounded-2xl hover:scale-105 hover:transition ease-in-out duration-200"
        />
      </div>

      <div class="w-full">
        <h2
          class="capitalize text-4xl animate__animated animate__fadeInUp animate__faster animate__delay-2s md:text-7xl opacity-20 font-bold"
        >
          about me
        </h2>
        <p
          class="pt-10 leading-loose text-base animate__animated animate__zoomIn animate__delay-2s"
        >
          <span class="text-accent font-medium"> Adefemi Gbadamosi </span>
          popularly known as Fola David. A Nigerian based got his MBBS degree
          from the prestigious University Of Lagos in 2017 after which he worked
          at the General hospital Odan Lagos, Lagos Island Maternity Hospital
          and Massey Children Hopsital.
        </p>

        <p
          class="text-base leading-loose pt-3 animate__animated animate__zoomIn animate__faster animate__delay-3s"
        >
          Foladavid is widely known as Nigeria’s Foremost Speedpainter with the
          unique ability to create eye-catching and colourful images upside down
          in less than 5 minutes. He has worked with and shared the same stage
          with many celebrities including Davido, 2baba, Alibaba, 9ice and many
          more. He has worked with many brands including Tolaram, Nigerian
          breweries, Total Energies and many others.
        </p>
      </div>
    </div>

    <div
      class="sm:w-[80%] px-5 my-[80px] flex flex-col lg:flex-row justify-between gap-10 md:gap-20 items-center max-w-[100%] mx-auto"
    >
      <div class="w-full order-2 lg:order-1">
        <h2
          class="capitalize text-xl font-bold tracking-wider animate__animated animate__fadeInUp animate__faster animate__delay-2s"
        >
          As a Medical Practitional
        </h2>
        <p
          class="pt-5 leading-loose text-base animate__animated animate__zoomIn animate__delay-2s"
        >
          Dr. Foladavid, In 2020, ventured into the communities during the
          covid-19 pandemic to help prevent the spread of the virus by educating
          the people, providing palliatives during the lockdown, sharing
          facemarks, encouraging social distancing and hand washing practices.
          He was 1 OF 3 Nominated for the Young Physician of the Year 2020 by
          Nigerian Medical Association, Lagos.
        </p>

        <p
          class="text-base leading-loose pt-3 animate__animated animate__zoomIn animate__delay-2s"
        >
          He also contributed to fighting the covid19 virus greatly with his
          work at the Lagos state isolation centre, Onikan, he donated N95
          facemasks to hospitals in Lagos state through his #COVEND initiative
          where over 200 African artists donated artworks to be sold to help
          fight the virus with his remarkable efforts documented by The Guardian
          News. In 2020 He was tagged the “Doctor by Day, Artist by Night” in an
          exclusive BBC interview on his work.
        </p>
        <p
          class="text-base leading-loose pt-3 animate__animated animate__zoomIn animate__delay-2s"
        >
          In 2021, he started his work at The Aesthetic Clinic (TAC) Lagos where
          he works in the dermatology department and continues his practice as a
          doctor to this day.
        </p>
        <p
          class="text-base leading-loose pt-3 animate__animated animate__zoomIn animate__delay-2s"
        >
          In 2022 He started the dermatological mission of the clinic with free
          dermatology outreaches to different communities with its maiden
          edition catering to 1500 residents of Ikate, Elegushi community. He is
          currently the marketing manager of the TAC worldwide and General
          manager of TAC Head Office.
        </p>
      </div>
      <div
        class="h-full order-1 lg:order-2 w-full md:h-[50%] md:w-[60%] border-8 border-[#1e2a3ff9] animate__animated animate__zoomIn animate__faster rounded-2xl"
      >
        <img
          src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1720030706/doc_t97fps.jpg"
          alt="about me"
          class="h-full w-full p-5 rounded-2xl hover:scale-105 hover:transition ease-in-out duration-200"
        />
      </div>
    </div>

    <div
      class="sm:w-[80%] px-5 flex flex-col lg:flex-row justify-between gap-10 md:gap-20 items-center max-w-[100%] mx-auto"
    >
      <div
        class="h-full w-full animate__animated animate__zoomIn md:h-[50%] md:w-[60%] border-8 border-[#1e2a3ff9] rounded-2xl"
      >
        <img
          src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1720030706/paint_ishfgx.jpg"
          alt="about me"
          class="h-full w-full p-5 rounded-2xl hover:scale-105 hover:transition ease-in-out duration-200"
        />
      </div>

      <div class="w-full">
        <h2
          class="capitalize text-xl font-bold tracking-wider animate__animated animate__fadeInUp"
        >
          As a Visual Artist
        </h2>
        <p
          class="pt-10 animate__animated animate__zoomIn animate__delay-2s leading-loose text-base"
        >
          Dr. Foladavid is a multidisciplinary artist working with various
          mediums, he works masterfully with charcoals, pastels, acrylics and
          oils, he draws his inspiration from his daily life as a doctor and a
          traveller. He also creates art with a focus on skin conditions
          especially those that affect the black skin and has done several
          exhibitions of his artworks across the globe. He has worked with
          several organisations including The Fondation René Touraine to help
          propagate the use of art in raising awareness on skin diseases. His
          work also touches on social and cultural issues that arise in his
          communities and have a direct impact on the inhabitants and the world
          at large.
        </p>

        <p
          class="text-base animate__animated animate__zoomIn animate__delay-2s leading-loose pt-3"
        >
          He performed at the Estoril conferences in 2023 in Cascais, Portugal
          creating a painting of one of the children met during his
          foundations’s visit to Ayanmelum, Anambra state and also featured in
          the official inaugural video of the President of Nigeria and featured
          on all the president’s social media pages.
        </p>
        <p
          class="text-base animate__animated animate__zoomIn animate__delay-2s leading-loose pt-3"
        >
          He also painted live at the inaugural ball to commemorate the swearing
          in of the president. The painting which was received by the president
          now hangs at the Aso Villa in a private collection.
        </p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts"></script>

<style scoped></style>
