<template>
  <section class="py-20 md:py-40 lg:py-40">
    <div class="container2">
      <div
        class="grid md:grid-cols-2 grid-cols-1 gap-x-16 gap-y-10 items-center pb-10 md:pb-20"
      >
        <div
          class="rounded-lg h-[80%] w-full mt-5 animate__animated animate__zoomIn animate__delay-1s"
        >
          <img
            src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718809961/img-6_fjfccz.jpg"
            alt=""
            class="w-full h-full object-cover object-top rounded-lg"
          />
        </div>

        <div class="space-y-8">
          <h2
            class="text-3xl pb-3 text-warning font-medium animate__animated animate__fadeInUp animate__delay-2s"
          >
            The Unity in Diversity Project
          </h2>
          <span
            class="text-sm md:text-base animate__animated animate__zoomIn animate__delay-2s"
          >
            Dr. Foladavid, medical doctor, visual artist, and humanitarian in
            collaboration with Tolaram Group, proudly unveils "The Unity in
            Diversity Project." This groundbreaking intiative is set to
            illuminate Nigeria's vibrant cultural mosaic through an immersive
            journey spanning visual art, documentary and cultural celebration.
          </span>

          <div class="animate__animated animate__zoomIn animate__delay-3s">
            <p class="text-base text-default-700 font-semibold">
              The project unfolds in three compelling phases:
            </p>
          </div>

          <div
            class="space-y-6 animate__animated animate__zoomIn animate__delay-4s"
          >
            <div>
              <p class="text-lg text-warning font-bold">Road to Record</p>
              <p>
                <span class="font-bold text-base">A Cultural Odyssey </span>
                <span class="text-sm md:text-base"
                  >Filming a mesmerizing road trip across different states of
                  Nigeria's multicultural landscape to experience firsthand,
                  these cultures which will form for their depiction on the
                  world record drawing and create a documentary called the "ROAD
                  TO RECORD"</span
                >
              </p>
            </div>
            <div>
              <p class="text-lg text-warning font-bold">World Record Attempt</p>
              <p>
                <span class="text-base font-bold"
                  >The largest drawing in the world by an individual
                </span>
                <span class="text-sm md:text-base"
                  >Prepare for history in the making as Dr.Foladavid aims to
                  break the record for the "world's largest drawing by an
                  individual" at onikan stadium, Lagos from 16th to 21st of July
                  2024. Spanning over 850km2, this monumental artwork will
                  depict a map of Nigeria showing its myriad tribes through
                  their unique attires, languages, art and essence
                </span>
              </p>
            </div>
            <div>
              <p class="text-lg text-warning font-bold">
                #RepYourTribe Carnival
              </p>
              <p>
                <span class="font-bold text-base"
                  >Celebrating Diverse Cultures
                </span>
                <span class="text-sm md:text-base"
                  >A worldwide call to every Nigerian to showoff their tribe
                  using the hashtag <span>#repyourtribe</span> on all online
                  platforms and a physical celebration with #RepYourTribe (#RYT)
                  Carnival live at the Stadium grounds alongside the creation of
                  the world record drawing, a digital and physical extravaganza
                  honoring Nigeria's cultural tapestry</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- mission and vision -->

      <section class="py-10 pt-10 md:pb-20 w-full dark:bg-white">
        <h3
          class="pb-8 md:pb-16 uppercase text-warning text-3xl md:text-3xl lg:text-4xl font-medium pl-5 md:pl-20"
        >
          MISSION & VISION
          <span class="block border-t-4 border-secondary w-[200px] mt-3"></span>
        </h3>

        <div class="flex flex-col md:flex-row w-full">
          <div
            class="py-10 rounded-tl-3xl md:rounded-bl-3xl md:py-20 px-10 bg-slate-300 bg-secondary w-full"
          >
            <div class="w-full md:px-20 mx-auto">
              <h4 class="text-black font-bold text-xl">Mission Statement:</h4>
              <span
                class="block border-t-4 border-white text-center w-[120px] mt-3 pb-10"
              ></span>
              <span class="text-black pt-10 text-sm md:text-base">
                Our mission is to celebrate and promote the rich cultural
                diversity of Nigeria, fostering unity and understanding through
                artistic expression, cultural exploration, and communal
                celebrations.
              </span>
            </div>
          </div>

          <div
            class="py-10 md:py-20 px-10 bg-black w-ful rounded-br-3xl md:rounded-tr-3xl"
          >
            <div class="w-full md:px-20 mx-auto">
              <h4 class="text-white font-bold text-xl">Vision:</h4>
              <span
                class="block border-t-4 border-secondary text-center w-[120px] mt-3 pb-10"
              ></span>
              <span class="text-white pt-10 text-sm md:text-base">
                A united Nigeria where every tribe and culture is celebrated and
                respected, contributing to a harmonious and vibrant nation.
              </span>
            </div>
          </div>
        </div>
      </section>

      <!--  -->
      <section
        class="mt-10 md:my-20 px-5 md:px-20 py-10 md:py-20 rounded-3xl bg-gradient-to-r from-[#38336b96] via-[#0b3451b7] to-[#38336b96]"
      >
        <h2
          class="text-3xl md:text-4xl text-center font-medium animate__animated animate__fadeInUp animate__delay-1s"
        >
          Fola David Care Foundation
        </h2>

        <div
          class="flex flex-col lg:flex-row items-center gap-5 md:gap-10 mt-10 md:mt-20"
        >
          <a
            href="http://www.foladavidfoundation.org"
            target="_blank"
            rel="noopener noreferrer"
            class="h-full lg:h-[600px] w-full rounded-lg"
          >
            <div class="animate__animated animate__zoomIn animate__delay-1s">
              <img
                src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718884478/foundation_jacd9b.jpg"
                class="h-full lg:h-[600px] w-full rounded-lg object-cover hover:bg-black hover:opacity-80 object-top"
                alt="..."
              />
            </div>
          </a>

          <div
            class="w-full animate__animated animate__zoomIn animate__delay-1s"
          >
            <h3 class="text-warning text-xl md:text-2xl pb-5">
              Passion To Make A Difference
            </h3>
            <span class="text-sm md:text-base">
              At the Foladavid Care Foundation, the work we do cuts across a
              wide range of causes in healthcare and art. We collaborate with
              individuals, governments and organizations to pursue healthy
              living, sustainability through the arts, and cooperation across a
              wide range of projects in many communities, nations and regions.
            </span>
            <p>
              <span class="mt-5 text-sm md:text-base">
                We ensure that children and mothers are able to have access to
                basic human amenities such as food, drugs, clothing and shelter,
                with the aim of improving their quality of life. To also help
                improve creativity amongst children, we create well-structured
                programs where children can actively participate in events that
                are sure to find and nurture innate talents. The goal of these
                programs is to help give these kids better futures.
              </span>
            </p>

            <!--  -->
            <div class="flex mt-10">
              <div
                @click="openModal"
                class="inline-flex items-center hover:text-primary-500 justify-center gap-2 text-sm md:text-base py-3 px-8 rounded-md text-primary bg-white/90 hover:bg-white transition-all duration-500 cursor-pointer"
              >
                Donate
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-move-right-icon h-6 w-6"
                >
                  <path d="M18 8L22 12L18 16"></path>
                  <path d="M2 12H22"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!--  -->
      <!-- <section>
        <section id="about" class="py-10 lg:py-20">
          <h3 class="py-5 text-2xl md:text-3xl">Our Teams</h3>

          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 justify-center items-center mx-auto w-full"
          >
            <img
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718809968/img-7_ydqf2c.jpg"
              class="rounded-lg w-full"
              alt="..."
            />
            <img
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718809960/img-6_cbbtof.png"
              class="rounded-lg w-full"
              alt="..."
            />
            <img
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718809972/img-8_aakyou.png"
              class="rounded-lg w-full"
              alt="..."
            />
            <img
              src="https://res.cloudinary.com/dtkbcuiit/image/upload/v1718809960/img-6_cbbtof.png"
              class="rounded-lg w-full"
              alt="..."
            />
          </div>
        </section>
      </section> -->

      <div v-if="modal">
        <DonateComp @close-modall="closeModal" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import DonateComp from "@/components/DonateComp.vue";
import { ref } from "vue";

import { ComponentIcon, LayersIcon } from "lucide-vue-next";

const modal = ref(false);

const openModal = () => {
  modal.value = true;
};
const closeModal = () => {
  modal.value = false;
};
</script>
